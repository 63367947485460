<template lang="pug">
div
  TableV2(
    :headers="headers"
    :items="items2.results || []"
    :pageCount="items2.count"
    :currentPage="items2.current"
    :querySearch="getListSubmittedDocumentsReport"
    :isLoading="isLoading"
    :actions="actions"
    :saveReport="getListSubmittedDocumentsReportExel"
    )
</template>

<script>
import Paginate from '@/components/atoms/Paginate.vue'
import ReportSearch from '@/components/molecules/ReportSearch/ReportSearch.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ReportDocumentEntries',
  components: {
    Paginate,
    ReportSearch
  },
  data () {
    return {
      headers: [
        { value: 'date_create',
          text: this.$i18n.t('createDate')
        },
        { value: 'sailor.id',
          text: this.$i18n.t('sailorId'),
          sortable: false
        },
        { value: 'crewing.name_ukr',
          text: this.$i18n.t('crewingCompany'),
          sortable: false
        },
        { value: 'sailorFullName',
          text: this.$i18n.t('fullName'),
          sortable: false
        },
        { value: 'typeDoc',
          text: this.$i18n.t('typeDoc'),
          sortable: false
        },
        { value: 'set_verification_at',
          text: this.$i18n.t('changeToVerificationDate'),
          sortable: false
        },
        { value: 'author.full_name_ukr',
          text: this.$i18n.t('author'),
          sortable: false
        },
        { value: 'status_document',
          text: this.$i18n.t('status')
        },
        { value: 'event',
          text: this.$i18n.t('go'),
          sortable: false
        }
      ],
      items: [],
      tableLoader: false,
      sortAcs: null,
      sortDesc: null,
      sortName: null,
      actions: [
        {
          id: 1,
          tooltip: 'go',
          action: this.handleClickToGoToDocumet,
          color: 'blue',
          name: 'mdi-arrow-right',
          isView: (item) => item.id
        }
      ]
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.report.isLoadingReport,
      items2: state => state.report.listDocumentSubmitted
    })
  },
  methods: {
    ...mapActions(['getListSubmittedDocumentsReport', 'getListSubmittedDocumentsReportExel']),
    handleClickToGoToDocumet (item) {
      this.$router.push({
        name: item.link,
        params: {
          id: item.sailor.id,
          documentID: item.service_record || item.id,
          lineID: item.id
        }
      })
    }
  }
}
</script>
